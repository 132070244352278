import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";
import Header from "components/Header";
import { Container, Flex } from "components/Views/utilites";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { PATHS } from "routes";

const Top = styled(Flex)`
  flex-grow: 1.5;
  margin-bottom: 15px;
`;

const Center = styled(Flex)`
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const Bottom = styled(Flex)`
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;
  margin-top: 30px;
`;

const DescriptionContainer = styled.div`
  background: #fff;
  padding: 20px 15px;
  border-radius: 8px;
`;

export const Info = () => {
  const navigate = useNavigate();
  const goBack = useCallback(() => {
    navigate(-1);
  }, []);
  const goToBuilder = useCallback(() => {
    navigate(PATHS.BUILDER);
  }, []);
  return (
    <Container>
      <Top>
        <Header
          logo
          title={"Kaleidoscope"}
          subtitle={"Create your own iGaming vision"}
        />
      </Top>
      <Center>
        <DescriptionContainer>
          <Typography variant={"description"}>
            Why the kaleidoscope? <br />
          </Typography>
          <br />
          <Typography variant={"description"}>
            Soft2Bet offers numerous authentic iGaming solutions powered by the
            latest tech and passion for gaming. Our ideas and innovations
            combine into endless combinations of brands, mechanics, and
            services. It's like a Kaleidoscope creating unique patterns with
            light and colored glass. <br />
          </Typography>
          <br />
          <Typography variant={"description"}>
            Create your own iGaming vision with our digital kaleidoscope.
            <br />
          </Typography>
          <br />
          <Box textAlign={"center"}>
            <Button
              onClick={goBack}
              sx={{
                padding: "14px 70px",
              }}
              variant={"outlined"}
            >
              OK
            </Button>
          </Box>
        </DescriptionContainer>
      </Center>
      <Bottom>
        <Button onClick={goToBuilder} fullWidth variant={"contained"}>
          start
        </Button>
      </Bottom>
    </Container>
  );
};

import { ReactNode } from "react";
import styled from "styled-components";

const ViewStyled = styled.div`
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
`;

interface ViewI {
  children: ReactNode;
}

function View({ children }: ViewI) {
  return <ViewStyled>{children}</ViewStyled>;
}

export default View;

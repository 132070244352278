import { forwardVariant } from "animations";
import { Canvas } from "components/AnimBG/Canvas";
import { Container } from "components/Views/BGContainer";
import View from "components/Views/View";
import { motion } from "framer-motion";
import { Outlet, useLocation } from "react-router";

export const Dots = () => {
  const location = useLocation();
  return (
    <motion.div
      key={location.pathname}
      initial={"hidden"}
      animate={"visible"}
      exit={"exit"}
      variants={forwardVariant}
    >
      <View>
        <Container>
          <div>
            <Canvas amount={50} line={60} speedMax={5} />
          </div>
          <div>
            <Outlet />
          </div>
        </Container>
      </View>
    </motion.div>
  );
};

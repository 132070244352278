import { createContext, ReactNode, useCallback, useReducer } from "react";

type AnimationsListType = {
  [key: string]: { name: string; video: string };
};

export const AnimationsList: AnimationsListType = {
  crystal_beam: {
    name: "Crystal Beam",
    video: "media/videos/crystal_beam.mp4",
  },
  electrify: {
    name: "Electrify",
    video: "media/videos/electrify.mp4",
  },
  gasoline: {
    name: "Gasoline",
    video: "media/videos/gasoline.mp4",
  },
  internet_love: {
    name: "Internet Love",
    video: "media/videos/internet_love.mp4",
  },
  neon_mercury: {
    name: "Neon Mercury",
    video: "media/videos/neon_mercury.mp4",
  },
  osmanthus_splash: {
    name: "Osmanthus Splash",
    video: "media/videos/osmanthus_splash.mp4",
  },
  solar_eclipse: {
    name: "Solar Eclipse",
    video: "media/videos/solar_eclipse.mp4",
  },
  space_cloud: {
    name: "Space Cloud",
    video: "media/videos/space_cloud.mp4",
  },
  space_flower: {
    name: "Space Flower",
    video: "media/videos/space_flower.mp4",
  },
  spaceship_interface: {
    name: "Spaceship Interface",
    video: "media/videos/spaceship_interface.mp4",
  },
  sun_cluster: {
    name: "Sun Cluster",
    video: "media/videos/sun_cluster.mp4",
  },
  waterflow: {
    name: "Waterflow",
    video: "media/videos/waterflow.mp4",
  },
};

export type Animation = {
  animation: AnimationName | null;
};

interface AnimationInterface {
  selectAnimation: (animation: AnimationName) => void;
  cancelAnimation: () => void;
}

export const AnimationContext = createContext<Animation & AnimationInterface>({
  animation: null,
  selectAnimation: (animation) => {},
  cancelAnimation: () => {},
});

const initialState: Animation = {
  animation: null,
};

type ActionTypes = "select" | "cancel";

type AnimationActions = { type: ActionTypes; payload: any };

export type AnimationName = keyof AnimationsListType;

const animationReducer = (
  state: Animation,
  action: AnimationActions,
): Animation => {
  const actions: { [key in ActionTypes]: (payload: any) => Animation } = {
    select: (payload) => ({ ...state, animation: payload }),
    cancel: () => ({ ...state, animation: null }),
  };
  return actions[action.type] ? actions[action.type](action.payload) : state;
};

export const AnimationContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(animationReducer, initialState);
  const selectAnimation = useCallback((payload: AnimationName) => {
    dispatch({ type: "select", payload });
  }, []);

  const cancelAnimation = useCallback(() => {
    dispatch({ type: "cancel", payload: null });
  }, []);
  return (
    <AnimationContext.Provider
      value={{ ...state, selectAnimation, cancelAnimation }}
    >
      {children}
    </AnimationContext.Provider>
  );
};

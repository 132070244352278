import { Dots, Kaleidoscope } from "components/Views";
import { AnimationContextProvider } from "context/Animation";
import Connection from "context/Connection";
import { BuilderPage, Timer } from "page/builder";
import { FormPage, Info, StartPage } from "page/info";
import { Location, TryLater } from "page/system";
import { Route, Routes, useLocation } from "react-router";
import { AnimatePresence } from "framer-motion";

export enum PATHS {
  ONBOARD = "/",
  FORM = "/form",
  DESCRIPTION = "/description",
  BUILDER = "/builder",
  TIMER = "timer",
  LOCATION = "/location",
  NOT_AVAILABLE = "/not_available",
}

export const AppRoutes = () => {
  const location = useLocation();
  return (
    <Connection>
      <AnimatePresence initial={false}>
        <Routes location={location} key={location.pathname}>
          <Route path={PATHS.ONBOARD} element={<Kaleidoscope />}>
            <Route index element={<StartPage />} />
            <Route path={PATHS.FORM} element={<FormPage />} />
            <Route path={PATHS.DESCRIPTION} element={<Info />} />
            <Route path={PATHS.LOCATION} element={<Location />} />
            <Route path={PATHS.NOT_AVAILABLE} element={<TryLater />} />
          </Route>
          <Route path={PATHS.BUILDER} element={<Dots />}>
            <Route
              index
              element={
                <AnimationContextProvider>
                  <BuilderPage />
                </AnimationContextProvider>
              }
            />
            <Route path={PATHS.TIMER} element={<Timer />} />
          </Route>
        </Routes>
      </AnimatePresence>
    </Connection>
  );
};

import { Variants } from "framer-motion";

export const baseAnimation: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { ease: "easeOut", duration: 10, delay: 0.5 },
  },
  exit: { opacity: 0 },
};

export const forwardVariant: Variants = {
  hidden: {
    x: "100vw",
    zIndex: 100,
    position: "absolute",
    top: 0,
    left: 0,
  },
  visible: {
    x: 0,
    zIndex: 100,
    transition: {
      ease: "easeInOut",
      // stiffness: 100,
      duration: 0.4,
    },
  },
  exit: {
    position: "absolute",
    top: 0,
    left: 0,
    x: "-50vw",
    zIndex: 50,
    transition: {
      ease: "easeIn",
      // stiffness: 100,
      // delay: 0.4,
      duration: 0.4,
    },
  },
};

export const builderAnimation: Variants = {
  hidden: {
    opacity: 0,
    y: 10,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: "easeInOut",
      stiffness: 100,
      duration: 0.4,
    },
  },
  exit: {
    opacity: 0,
    y: -10,
    position: "absolute",
    transition: {
      ease: "easeIn",
      stiffness: 100,
      // delay: 0.4,
      duration: 0.4,
    },
  },
};

import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import Header from "components/Header";
import { Container } from "components/Views/utilites";
import location from "media/location.png";
import { useNavigate } from "react-router";
import { PATHS } from "routes";

export const Dialog = styled.div`
  margin-top: 30px;
  flex-grow: 1;
  text-align: center;
  background: #fff;
  padding: 20px 15px;
  border-radius: 8px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

export const Location = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Header
        infoButton
        logo
        title={"Kaleidoscope"}
        subtitle={"Create your own iGaming vision"}
      />
      <Dialog>
        <Typography sx={{ fontSize: 20, fontWeight: 400 }}>
          Sorry, this app works only in the <br />
          Soft2Bet pavilion.
        </Typography>
        <img src={"/media/location.png"} alt="Soft2Bet location" />
        <Button
          sx={{
            padding: "14px 70px",
          }}
          variant={"outlined"}
          onClick={() => navigate(PATHS.ONBOARD)}
        >
          OK
        </Button>
      </Dialog>
    </Container>
  );
};

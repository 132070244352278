import { HTMLProps } from "react";

export const calcViewHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

export function calculateFluidFontSize(
  minFontSize: number,
  maxFontSize: number,
  minWidth: number,
  maxWidth: number,
) {
  // Get the current viewport width
  const viewportWidth = window.innerWidth;

  // Ensure the viewport width is within the specified range
  const clampedWidth = Math.min(Math.max(viewportWidth, minWidth), maxWidth);

  // Calculate the fluid font size based on the viewport width
  return (
    minFontSize +
    ((clampedWidth - minWidth) * (maxFontSize - minFontSize)) /
      (maxWidth - minWidth)
  );
}

export const resizeHandler = () => {
  const fluidFontSize = calculateFluidFontSize(8, 10, 375, 430);
  const html = document.querySelector("html");
  if (html) html.style.fontSize = `${fluidFontSize}px`;
};

export const calculatedVh = (percent: number) =>
  `calc(var(--vh, 1vh) * ${percent * 100})`;

export const videoElementAutoplayProps: HTMLProps<HTMLVideoElement> = {
  autoPlay: true,
  loop: true,
  muted: true,
  playsInline: true,
  preload: "auto",
  crossOrigin: "anonymous",
  type: "video/mp4",
};

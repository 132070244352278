import styled from "@emotion/styled";

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled(Flex)`
  height: 100%;
`;

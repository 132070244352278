import styled from "@emotion/styled";
import { Button, Grid, Typography } from "@mui/material";
import { baseAnimation, builderAnimation } from "animations";
import {
  AnimationContext,
  AnimationName,
  AnimationsList,
} from "context/Animation";
import { useConnection } from "context/useConnection";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router";
import { PATHS } from "routes";
import { calculatedVh, videoElementAutoplayProps } from "utilites";

const VideoContainer = styled.div<{
  $active: boolean;
  $withDescription: boolean;
}>`
  width: 100%;

  & > video {
    width: 100%;
    ${({ $withDescription }) =>
      $withDescription ? `height:${calculatedVh(250 / 739)}` : "height:100%"};
    object-fit: cover;
    object-position: center;
    border-radius: 4px;
    transition: ease-out 0.2s;
    transform: ${({ $active }) => ($active ? "scale(0.9)" : "scale(0.8)")};
    box-shadow: ${({ $active }) =>
      $active ? "rgba(255,255,255, 0.8) 0px 0px 3px 5px" : "none"};
  }
`;

const AnimationElement = ({
  anim,
  withDescription = false,
}: {
  anim: AnimationName;
  withDescription?: boolean;
}) => {
  const { animation, selectAnimation } = useContext(AnimationContext);
  const currentAnim = AnimationsList[anim];
  if (withDescription) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <VideoContainer
          $active={animation === anim}
          $withDescription={withDescription}
        >
          <video {...videoElementAutoplayProps} src={currentAnim.video} />
        </VideoContainer>
        <Typography variant={"videoCapture"}>{currentAnim.name}</Typography>
        <Typography variant={"animationNames"}>Take a selfie</Typography>{" "}
        <Typography variant={"finishInfoSec"}>
          And tag <span style={{ fontWeight: "bold" }}>@soft2bet_group</span>&
          <br />
          <span style={{ fontWeight: "bold" }}>@kablukasculptures</span> in your{" "}
          <br /> posts
        </Typography>
      </div>
    );
  }
  return (
    <Grid
      item
      xs={6}
      onClick={() => {
        console.log(anim);
        selectAnimation(anim);
      }}
      textAlign={"center"}
    >
      <VideoContainer
        $active={animation === anim}
        $withDescription={withDescription}
      >
        <video {...videoElementAutoplayProps} src={currentAnim.video} />
      </VideoContainer>
      <Typography variant={"videoCapture"}>{currentAnim.name}</Typography>
    </Grid>
  );
};

const BuilderContainer = styled.div`
  flex-grow: 1;
`;

const BuilderTitle = styled.div`
  margin-top: ${calculatedVh(26 / 739)};
`;

const BuilderView = styled.div<{ $overflow: boolean }>`
  height: ${calculatedVh(450 / 739)};
  margin: ${calculatedVh(26 / 739)} auto 0;
  position: relative;
  overflow: auto;
`;

const BuilderControl = motion(styled.div`
  margin-top: ${calculatedVh(26 / 739)};
  height: ${calculatedVh(108 / 739)};
`);

const BuilderApply = styled.div`
  text-align: center;
  margin-top: ${calculatedVh(18 / 739)};
`;

const steps = [
  <Typography variant={"step"}>Step 1</Typography>,
  <Typography variant={"step"}>Step 2</Typography>,
  <Typography variant={"step"}>Step 3</Typography>,
];
const stepsDescriptions = [
  <Typography variant={"stepDescription"}>choose your kaleidoscope</Typography>,
  <Typography variant={"stepDescription"}>choose your kaleidoscope</Typography>,
];

const animationNames = {
  A: <Typography variant={"animationNames"}>Creative</Typography>,
  B: <Typography variant={"animationNames"}>Ambitious</Typography>,
  C: <Typography variant={"animationNames"}>The winner</Typography>,
};

const animationDescription = {
  A: (
    <Typography variant={"animationDescription"}>
      Build unique and distinctive brands with our <br /> tailor-made design.
    </Typography>
  ),
  B: (
    <Typography variant={"animationDescription"}>
      Feel the power of innovation, quality service <br /> and support for
      ambitious achievements
    </Typography>
  ),
  C: (
    <Typography variant={"animationDescription"}>
      Turn your aims into wins with innovation,
      <br /> quality and support
    </Typography>
  ),
};

const Builder = () => {
  const [lock, setLock] = useState(false);
  const { addAnimation } = useConnection();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const { animation } = useContext(AnimationContext);

  const nextStep = useCallback(async () => {
    const prev = step + 1;
    if (prev > 1) {
      try {
        setLock(true);
        const res = await addAnimation(animation);

        if (res === "Not allow") {
          return navigate(PATHS.LOCATION);
        }
        if (res === "block") {
          return navigate(PATHS.NOT_AVAILABLE);
        }
        return void navigate(PATHS.TIMER, { state: res });
      } catch (e: any) {
        if (e.code === 1) return navigate(PATHS.LOCATION);
        setLock(false);
        return;
      }
    }
    return void setStep(prev);
  }, [step, animation]);

  const prevStep = useCallback(() => {
    const prev = step - 1;
    if (prev < 0) {
      return void navigate(PATHS.FORM);
    }
    return void setStep(prev);
  }, [step]);

  return (
    <AnimatePresence>
      <BuilderContainer>
        <BuilderTitle>{stepsDescriptions.at(step)}</BuilderTitle>

        <BuilderView $overflow={!step}>
          <AnimatePresence>
            {!step && (
              <motion.div
                key={step}
                initial={"hidden"}
                animate={"visible"}
                exit={"exit"}
                variants={baseAnimation}
              >
                <Grid container spacing={4}>
                  {Object.keys(AnimationsList).map((anim) => (
                    <AnimationElement key={anim} anim={anim} />
                  ))}
                </Grid>
              </motion.div>
            )}
            {animation && step && (
              <motion.div
                style={{ height: "100%" }}
                key={step}
                initial={"hidden"}
                animate={"visible"}
                exit={"exit"}
                variants={baseAnimation}
              >
                <AnimationElement anim={animation} withDescription />
              </motion.div>
            )}
          </AnimatePresence>
        </BuilderView>
        <BuilderApply>
          <Button
            disabled={lock || !animation}
            onClick={nextStep}
            fullWidth
            variant={"contained"}
          >
            Choose
          </Button>
          <Button sx={{ mt: `18px` }} onClick={prevStep} variant={"text"}>
            Back
          </Button>
        </BuilderApply>
      </BuilderContainer>
    </AnimatePresence>
  );
};

export default Builder;

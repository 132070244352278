import { Dot } from "components/AnimBG/Dot";
import { CanvasComponents, InitDot } from "components/AnimBG/types";
import { drawAnimation, resizeWindow } from "components/AnimBG/utils";
import { memo, useLayoutEffect, useRef } from "react";

const listenersOptions: AddEventListenerOptions = {
  passive: true,
};

export const Canvas = memo<CanvasComponents>(
  ({ amount, speedMax, line }) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    useLayoutEffect(() => {
      const canvas = canvasRef.current;
      let width = window.innerWidth;
      let height = window.innerHeight;
      if (!canvas) return;
      canvas.width = width;
      canvas.height = height;
      canvas.style.filter = "blur(1.5px)";
      canvas.style.opacity = "0.6";
      canvas.style.background = "#000";
      const context = canvas.getContext("2d");
      if (!context) return;
      const initDotInfo: InitDot = {
        width: window.innerWidth,
        height: window.innerHeight,
        speedMax: speedMax,
      };
      const dots = Array.from({ length: amount }, () => new Dot(initDotInfo));

      window.addEventListener(
        "resize",
        resizeWindow.bind(null, canvas),
        listenersOptions,
      );

      const fps = setInterval(
        drawAnimation.bind({
          context,
          dots,
          line,
        }),
        1000 / 60,
      );
      return () => {
        window.removeEventListener("resize", resizeWindow.bind(null, canvas));
        clearInterval(fps);
      };
    }, [canvasRef.current]);
    return <canvas ref={canvasRef} />;
  },
  (prevProps, nextProps) => {
    return (
      prevProps.line === nextProps.line ||
      prevProps.speedMax === nextProps.speedMax ||
      prevProps.amount === nextProps.amount
    );
  },
);

import { useCallback } from "react";

export const useLocation = () => {
  return useCallback<() => Promise<GeolocationPosition>>(
    () =>
      new Promise((resolve, reject) => {
        window.navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        });
      }),
    [],
  );
};

import { useLocation } from "context/useLocation";
import { useCallback, useContext, useEffect } from "react";
import { SocketIO } from "./Connection";
type ConnectionHook = {
  isConnected: boolean;
  addAnimation: (animation: any) => Promise<any>;
  sendGeolocation: (lat: number, long: number) => Promise<2 | null>;
};

export const useConnection = (
  callBack?: ((delay: number) => void) | undefined,
): ConnectionHook => {
  const getLocation = useLocation();
  const { socket, shouldValidateLocation } = useContext(SocketIO);
  const addAnimation = useCallback(
    async (animation: any) => {
      if (!socket) return null;
      if (!shouldValidateLocation) return socket.emitWithAck("add", animation);
      const location = await getLocation();
      await sendGeolocation(
        location.coords.latitude,
        location.coords.longitude,
      );
      return socket.emitWithAck("add", animation);
    },
    [shouldValidateLocation, socket],
  );

  const sendGeolocation = useCallback(
    async (lat: number, long: number): Promise<2 | null> => {
      if (!socket) return null;
      return await socket.emitWithAck("geo", { lat, long });
    },
    [socket],
  );

  useEffect(() => {
    if (socket && callBack) {
      socket.on("decrease", callBack);
      return () => {
        socket.off("decrease", callBack);
      };
    }
  }, [socket]);

  return {
    isConnected: !!socket && socket.connected,
    addAnimation,
    sendGeolocation,
  };
};

import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import Header from "components/Header";
import { Flex, Container } from "components/Views/utilites";
import { motion } from "framer-motion";
import { createBy, mainXL } from "media/logos";
import { useNavigate } from "react-router";
import { PATHS } from "routes";
import { calculatedVh } from "utilites";

const Top = styled(Flex)`
  flex-grow: 1.5;
`;

const Center = styled(Flex)`
  justify-content: center;
  align-items: center;
`;

const Bottom = styled(Flex)`
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: ${calculatedVh(20 / 739)};
`;

export const StartPage = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Top>
        <Header infoButton />
      </Top>
      <Center>
        <img key={"logo"} src={mainXL} alt={"soft2bet"} />
        <Typography key={"title"} component={"h1"} variant={"startPageTitle"}>
          kaleidoscope
        </Typography>
        <Typography
          key={"subTitle"}
          component={"p"}
          variant={"startPageSubTitle"}
        >
          Create your own iGaming vision
        </Typography>
      </Center>
      <Bottom>
        <Button
          key={"button"}
          onClick={() => navigate(PATHS.FORM)}
          fullWidth
          variant={"contained"}
          sx={{ mb: calculatedVh(50 / 739) }}
        >
          start
        </Button>
        <img
          width={"150px"}
          key={"logo"}
          src={createBy}
          alt={"Kabluka light & digital sculptures & expolight"}
        />
      </Bottom>
    </Container>
  );
};

import styled from "@emotion/styled";
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import Header from "components/Header";
import { Container, Flex } from "components/Views/utilites";
import { FieldValidator } from "final-form";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { PATHS } from "routes";
import { calculatedVh } from "utilites";
import { Form, Field } from "react-final-form";

const Top = styled(Flex)`
  flex-grow: 1.5;
`;

const Center = styled(Flex)`
  justify-content: center;
  align-items: center;
`;

const Bottom = styled(Flex)`
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: ${calculatedVh(52.5 / 739)};
`;

const FormContainer = styled.div`
  margin: auto;
  max-width: 450px;
  padding: 22px 20px;
  background: #fff;
  border-radius: 8px;
  text-align: center;
`;

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const required: FieldValidator<string> = (value) =>
  !value?.length ? "Name required." : undefined;
const emailValidator = (value: string) =>
  !emailRegex.test(value) ? "Invalid email." : undefined;

export const FormPage = () => {
  const goToCreator = useCallback(() => {
    navigate(PATHS.BUILDER);
  }, []);
  const submit = useCallback(async (values: any) => {
    await fetch(
      process.env.NODE_ENV === "production"
        ? "/user"
        : "https://s2b.space/user",
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(values),
      },
    ).catch(console.error);
    goToCreator();
  }, []);
  const navigate = useNavigate();

  return (
    <Form
      initialValues={{
        name: "",
        email: "",
        accept: false,
      }}
      onSubmit={submit}
      render={({ values: { accept }, valid, submitting, form }) => (
        <Container>
          <Top>
            <Header
              infoButton
              logo
              title={"Kaleidoscope"}
              subtitle={"Create your own iGaming vision"}
            />
          </Top>
          <Center>
            <FormContainer>
              <Typography variant={"formText"}>
                Enter your contact to begin
              </Typography>
              <Field
                name={"name"}
                validate={required}
                render={({ input, meta: { invalid, touched, error } }) => (
                  <TextField
                    color={"s2b"}
                    sx={{
                      mt: "10px",
                    }}
                    label={"Your name"}
                    variant={"standard"}
                    fullWidth
                    {...input}
                    name={"name"}
                    error={touched && invalid}
                    helperText={touched && error}
                  />
                )}
              />
              <Field
                name={"email"}
                validate={emailValidator}
                render={({ input, meta: { invalid, touched, error } }) => (
                  <TextField
                    color={"s2b"}
                    sx={{
                      mt: "10px",
                    }}
                    label={"Email"}
                    variant={"standard"}
                    fullWidth
                    {...input}
                    name={"email"}
                    error={touched && invalid}
                    helperText={touched && error}
                  />
                )}
              />
              <FormControlLabel
                sx={{ mt: "10px" }}
                componentsProps={{
                  typography: {
                    textAlign: "left",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: 1.12,
                  },
                }}
                control={
                  <Field
                    name={"accept"}
                    type={"checkbox"}
                    render={({ input: { checked, onChange } }) => (
                      <Checkbox
                        checked={checked}
                        onChange={onChange}
                        sx={{
                          color: "#005038",
                          "&.Mui-checked": {
                            color: "#005038",
                          },
                        }}
                      />
                    )}
                  />
                }
                label="By filling out the form I agree to the processing of my personal data"
              />
            </FormContainer>
          </Center>
          <Bottom>
            <Button
              onClick={form.submit}
              disabled={!accept || !valid || submitting}
              variant={"contained"}
              fullWidth
            >
              confirm
            </Button>
            <Button variant={"text"} onClick={goToCreator}>
              skip
            </Button>
          </Bottom>
        </Container>
      )}
    />
  );
};

import React, {
  createContext,
  FC,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router";
import { PATHS } from "routes";
import socket_io, { Socket } from "socket.io-client";

type ConnectionContext = {
  isConnected: boolean | null;
  socket: Socket | null;
  shouldValidateLocation: boolean;
};

export const SocketIO = createContext<ConnectionContext>({
  isConnected: null,
  socket: null,
  shouldValidateLocation: true,
});

enum Messages {
  appOff = "appOff",
  techWork = "techWork",
}

const Connection: FC<{ children: ReactElement }> = ({ children }) => {
  const socket = useRef<Socket | null>(null);
  const [isConnected, setConnection] = useState<boolean | null>(null);
  const [shouldValidateLocation, setShouldValidateLocation] =
    useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (socket.current) return;
    socket.current = socket_io(
      process.env.NODE_ENV === "production" ? "/" : "https://s2b.space",
      {
        transports: ["websocket"],
      },
    );

    socket.current?.on("connect", async () => {
      const validateLocation = await socket.current?.emitWithAck("location");
      setShouldValidateLocation(validateLocation.check);
      setConnection(true);
      console.log("%c Success connect!", "color:green");
    });

    socket.current?.on("disconnect", () => {
      console.log("%c Connection lost!", "color:red");
      setConnection(false);
    });

    socket.current?.on("connect_error", () => {
      setConnection(false);
    });
    socket.current?.on("queueReset", () => {
      window.location.reload();
    });
    socket.current?.on("sysm", (messageType: Messages) => {});
    socket.current?.on("locationSub", ({ check }) => {
      setShouldValidateLocation(check);
    });

    return () => {
      if (socket.current?.connected) {
        socket.current?.close();
        socket.current = null;
      }
    };
  }, []);
  useEffect(() => {
    if (isConnected === false && location.pathname.includes(PATHS.BUILDER)) {
      navigate(PATHS.NOT_AVAILABLE);
    }
  }, [isConnected, location]);
  return (
    <SocketIO.Provider
      value={{ isConnected, socket: socket.current, shouldValidateLocation }}
    >
      {children}
    </SocketIO.Provider>
  );
};

export default Connection;

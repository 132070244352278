import { forwardVariant } from "animations";
import { Container } from "components/Views/BGContainer";
import { Outlet, useLocation } from "react-router";
import styled from "styled-components";
import { videoElementAutoplayProps } from "utilites";
import View from "./View";
import { motion } from "framer-motion";
const Video = styled.video`
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const Kaleidoscope = () => {
  const location = useLocation();
  return (
    <motion.div
      key={location.pathname}
      initial={"hidden"}
      animate={"visible"}
      exit={"exit"}
      variants={forwardVariant}
    >
      <View>
        <Container>
          <div>
            <Video {...videoElementAutoplayProps} src={"media/bg.mp4#t=0.1"} />
          </div>

          <Outlet />
        </Container>
      </View>
    </motion.div>
  );
};

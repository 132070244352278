import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { builderAnimation } from "animations";
import Header from "components/Header";
import { Container } from "components/Views/utilites";
import { useConnection } from "context/useConnection";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { PATHS } from "routes";
const audio = new Audio("media/timer.mp3");
enum RunState {
  WAIT = "wait",
  RUN = "run",
}

const Circle = styled.div<{ $state?: RunState }>`
  margin: 25px auto 0 auto;
  border-radius: 50%;
  position: relative;
  height: calc(var(--vh, vh) * 32);
  aspect-ratio: 1/1;
  box-shadow:
    0 3px 13.5px 0 ${({ $state }) => ($state === "run" ? "#69E29F" : "#69d9e8")}
      inset,
    0px 62px 75px -36px #666666 inset;
  backdrop-filter: blur(7px);
  transition: box-shadow ease-in-out 0.5s;
  & > span {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const TimeView = ({ state, sec }: { state: RunState; sec: number }) => {
  return (
    <Circle $state={state}>
      <Typography variant={"time"}>{sec}</Typography>
    </Circle>
  );
};

const TextContainer = motion(styled.div<{ key: string; finish?: boolean }>`
  margin-top: 45px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  ${({ finish }) => (finish ? "gap: 16px;" : "")}
`);

const TopContainer = styled.div`
  min-height: 100px;
`;

const soundEffect = () => {
  audio.play().catch(console.error);
  window.navigator.vibrate &&
    window.navigator.vibrate([
      150, 850, 150, 850, 150, 850, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
      50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
      50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
    ]);
};

export const Timer = () => {
  const location = useLocation();
  const [state, setState] = useState<RunState>(RunState.WAIT);
  const [sec, setSec] = useState<number>(location.state.waitTime);
  const navigate = useNavigate();
  useConnection((delay) => {
    setSec(delay);
  });
  useEffect(() => {
    if (sec <= 0 && state === RunState.RUN) {
      return;
    }
    if (sec <= 0 && state === RunState.WAIT) {
      setState(RunState.RUN);
      setSec(location.state.duration);
      return;
    }
    const res = sec % 1000;

    const timeout = res
      ? setTimeout(setSec, res, sec - res)
      : setTimeout(setSec, 1000, sec - 1000);
    return () => clearTimeout(timeout);
  }, [sec, location.state.waitTime, location.state.duration]);
  useEffect(() => console.log(sec), [sec]);
  useEffect(() => {
    if (state === RunState.WAIT && sec === 3) {
      soundEffect();
    }
  }, [sec, state]);
  const wait = state === RunState.WAIT;
  const run = state === RunState.RUN && sec > 0;
  const finish = state === RunState.RUN && sec === 0;
  return (
    <Container>
      <Header logo />
      <TopContainer>
        {wait && (
          <>
            <br />
            <br />
            <Typography variant={"stepDescription"}>one more step</Typography>
          </>
        )}

        {finish && (
          <div style={{ textAlign: "center" }}>
            <Typography variant={"finishInfoPrim"}>
              Your Kaleidoscope was amazing!
            </Typography>
            <br />
            <Typography variant={"finishInfoSec"}>
              Stay with us and try again <br /> in 10 minutes.
            </Typography>
          </div>
        )}
      </TopContainer>

      <TimeView state={state} sec={Math.round(sec / 1e3)} />
      {wait && (
        <TextContainer
          key={"wait"}
          initial={"hidden"}
          animate={"visible"}
          exit={"exit"}
          variants={builderAnimation}
        >
          <Typography textAlign={"center"} variant={"timeScreenSec"}>
            Please, wait. <br />
            Your Kaleidoscope <br />
            is creating...
          </Typography>
        </TextContainer>
      )}
      {run && (
        <TextContainer
          key={"run"}
          initial={"hidden"}
          animate={"visible"}
          exit={"exit"}
          variants={builderAnimation}
        >
          <Typography textAlign={"center"} variant={"timeScreenSec"}>
            Your Kaleidoscope is on!
          </Typography>
          <Typography variant={"startPageTitle"}>let’s go!</Typography>
          <Typography variant={"timeScreenSec"}>Take a Selfie</Typography>
        </TextContainer>
      )}
      {finish && (
        <TextContainer
          key={"finish"}
          finish
          initial={"hidden"}
          animate={"visible"}
          exit={"exit"}
          variants={builderAnimation}
        >
          <Button
            fullWidth
            sx={{ maxWidth: 400 }}
            variant={"contained"}
            onClick={() => navigate(PATHS.BUILDER)}
          >
            Create one more
          </Button>
          <Typography variant={"startPageSubTitle"}>
            Learn more about Soft2Bet turnkey <br /> solutions.
          </Typography>
          <Button
            sx={{
              paddingTop: 0,
            }}
            variant={"text"}
            onClick={() => window.location.replace("https://www.soft2bet.com/")}
          >
            Learn now
          </Button>
        </TextContainer>
      )}
    </Container>
  );
};

import styled from "@emotion/styled";
import { Icon, IconButton, Typography } from "@mui/material";
import infoButtonIcon from "media/кнопка інфо.png";
import { mainS } from "media/logos";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { PATHS } from "routes";

type String = string | null;

interface HeaderProps {
  logo?: boolean;
  infoButton?: boolean;
  title?: String;
  subtitle?: String;
}

const Container = styled.div`
  padding-top: calc(var(--vh, 1vh) * 6.7);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  @media (max-width: 375px) {
    padding-top: 20px;
  }
`;

const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;

const Header = ({
  logo = false,
  infoButton = false,
  title = null,
  subtitle = null,
}: HeaderProps) => {
  const navigate = useNavigate();
  const goToInfo = useCallback(() => {
    navigate(PATHS.DESCRIPTION);
  }, []);
  return (
    <Container>
      <TextContainer>
        {logo && <img src={mainS} alt="soft2bet" />}
        {title && <Typography variant={"headerTitle"}>{title}</Typography>}
        {subtitle && (
          <Typography variant={"headerSubTitle"}>{subtitle}</Typography>
        )}
      </TextContainer>
      {infoButton && (
        <IconButton
          onClick={goToInfo}
          sx={{
            padding: 0,
          }}
        >
          <img width={"28px"} src={"/media/infoBtn.png"} alt={"info"} />
        </IconButton>
      )}
    </Container>
  );
};

export default Header;

import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import Header from "components/Header";
import { Container } from "components/Views/utilites";

export const Dialog = styled.div`
  text-align: center;
  background: #fff;
  padding: 20px 15px;
  border-radius: 8px;
`;

export const TryLater = () => {
  return (
    <Container>
      <Header
        infoButton
        logo
        title={"Kaleidoscope"}
        subtitle={"Create your own iGaming vision"}
      />
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Dialog>
          <Typography sx={{ fontSize: 20 }}>
            The application is not available, <br />
            try again later.
          </Typography>
          <Button
            sx={{
              padding: "14px 70px",
              marginTop: "34px",
            }}
            variant={"outlined"}
            onClick={() => window.location.reload()}
          >
            OK
          </Button>
        </Dialog>
      </div>
    </Container>
  );
};

import { CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { MemoryRouter } from "react-router-dom";
import theme from "theme";
import { calcViewHeight, resizeHandler } from "utilites";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as sw from "./serviceWorkerRegistration";
calcViewHeight();
window.addEventListener("resize", calcViewHeight, { passive: true });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
window.addEventListener("resize", resizeHandler, { passive: true });
resizeHandler();
root.render(
  <React.StrictMode>
    <MemoryRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </MemoryRouter>
  </React.StrictMode>,
);

sw.register({
  onUpdate: (sw: any) => {
    sw && sw.waiting.postMessage({ type: "SKIP_WAITING" });
  },
});

import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  & > div:nth-child(1) {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  & > div:nth-child(2) {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    padding-left: 25px;
    padding-right: 25px;
  }
`;

import Builder from "components/Builder";
import Header from "components/Header";
import { Container, Flex } from "components/Views/utilites";

export const BuilderPage = () => {
  return (
    <Container>
      <Header logo />
      <Builder />
    </Container>
  );
};

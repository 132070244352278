import { InitDot } from "components/AnimBG/types";
import { randomInteger } from "components/AnimBG/utils";

export class Dot {
  public x: number;
  public y: number;
  public speed: number;
  public dx: number;
  public dy: number;
  public rad: number;
  constructor({ width, height, speedMax }: InitDot) {
    //INIT position
    this.x = randomInteger(0, width);
    this.y = randomInteger(0, height);

    this.speed = randomInteger(1, speedMax) * 0.1;

    this.dx = randomInteger(-this.speed, this.speed);
    this.dy = randomInteger(-this.speed, this.speed);
    this.rad = randomInteger(1, 3);
  }
}

import { createTheme, responsiveFontSizes } from "@mui/material";
import { green } from "@mui/material/colors";
import { CSSProperties } from "react";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    startPageTitle: CSSProperties;
    startPageSubTitle: CSSProperties;
    headerTitle: CSSProperties;
    headerSubTitle: CSSProperties;
    formText: CSSProperties;
    description: CSSProperties;
    step: CSSProperties;
    stepDescription: CSSProperties;
    animationNames: CSSProperties;
    animationDescription: CSSProperties;
    time: CSSProperties;
    timeScreenSec: CSSProperties;
    timeScreenPrim: CSSProperties;
    finishInfoPrim: CSSProperties;
    finishInfoSec: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    startPageTitle?: CSSProperties;
    startPageSubTitle?: CSSProperties;
    headerTitle: CSSProperties;
    headerSubTitle: CSSProperties;
    formText: CSSProperties;
    description: CSSProperties;
    step: CSSProperties;
    stepDescription: CSSProperties;
    animationNames: CSSProperties;
    animationDescription: CSSProperties;
    time: CSSProperties;
    timeScreenSec: CSSProperties;
    timeScreenPrim: CSSProperties;
    finishInfoPrim: CSSProperties;
    finishInfoSec: CSSProperties;
    videoCapture: CSSProperties;
  }
  interface Palette {
    s2b: Palette["primary"];
  }

  interface PaletteOptions {
    s2b?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides {
    s2b: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    startPageTitle: true;
    startPageSubTitle: true;
    headerTitle: true;
    headerSubTitle: true;
    formText: true;
    description: true;
    step: true;
    stepDescription: true;
    animationNames: true;
    animationDescription: true;
    time: true;
    timeScreenSec: true;
    timeScreenPrim: true;
    finishInfoPrim: true;
    finishInfoSec: true;
    videoCapture: true;
  }
}

export const textGradient = `linear-gradient(
  75deg,
  hsl(130deg 76% 62%) 0%,
  hsl(134deg 77% 62%) 5%,
  hsl(137deg 78% 62%) 9%,
  hsl(140deg 79% 62%) 14%,
  hsl(143deg 80% 63%) 18%,
  hsl(146deg 80% 63%) 23%,
  hsl(148deg 81% 64%) 27%,
  hsl(150deg 81% 64%) 32%,
  hsl(152deg 82% 65%) 36%,
  hsl(154deg 82% 66%) 41%,
  hsl(156deg 82% 67%) 45%,
  hsl(157deg 81% 68%) 50%,
  hsl(162deg 79% 65%) 55%,
  hsl(167deg 77% 62%) 59%,
  hsl(170deg 75% 58%) 64%,
  hsl(174deg 73% 55%) 68%,
  hsl(176deg 72% 51%) 73%,
  hsl(179deg 80% 47%) 77%,
  hsl(181deg 98% 42%) 82%,
  hsl(183deg 100% 42%) 86%,
  hsl(184deg 100% 42%) 91%,
  hsl(186deg 100% 43%) 95%,
  hsl(187deg 95% 44%) 100%
)`;

const theme = createTheme({
  palette: {
    s2b: {
      main: "#005038",
      light: "#2DA46C",
    },
  },
  typography: {
    fontFamily: "Formular, serif",
    fontStyle: "normal",
    videoCapture: {
      fontSize: "2rem",
      color: "#fff",
      fontWeight: 500,
    },
    startPageTitle: {
      textTransform: "uppercase",
      fontSize: "4.6rem",
      background: textGradient,
      backgroundClip: "text",
      // @ts-ignore
      textFillColor: "transparent",
      fontWeight: 900,
    },
    startPageSubTitle: {
      fontSize: "1.6rem",
      color: "#fff",
      fontWeight: 300,
    },
    headerTitle: {
      textTransform: "uppercase",
      fontSize: "2rem",
      background: textGradient,
      backgroundClip: "text",
      // @ts-ignore
      textFillColor: "transparent",
      fontWeight: 900,
    },
    headerSubTitle: {
      fontSize: "1.4rem",
      color: "#fff",
      fontWeight: 300,
    },
    formText: {
      fontSize: "2.5rem",
      fontWeight: 500,
      textAlign: "center",
    },
    description: {
      fontSize: "2rem",
      fontWeight: 400,
      lineHeight: 1,
    },
    step: { fontSize: "1.4rem", fontWeight: 400, color: "#fff" },
    stepDescription: {
      fontSize: "1.6rem",
      fontWeight: 500,
      color: "#2DA46C",
      textTransform: "uppercase",
    },
    animationNames: {
      fontSize: "4rem",
      fontWeight: 900,
      textTransform: "uppercase",
      background: textGradient,
      backgroundClip: "text",
      // @ts-ignore
      textFillColor: "transparent",
    },
    animationDescription: {
      fontSize: "1.4rem",
      fontWeight: 500,
      color: "#fff",
      opacity: 0.8,
    },
    time: {
      fontSize: "12.8rem",
      fontWeight: 900,
      color: "#fff",
    },
    timeScreenSec: {
      fontSize: "2.4rem",
      fontWeight: 300,
      color: "#fff",
    },

    timeScreenPrim: {
      textTransform: "uppercase",
      fontSize: "3.6rem",
      background: textGradient,
      backgroundClip: "text",
      // @ts-ignore
      textFillColor: "transparent",
      fontWeight: 900,
    },
    finishInfoPrim: {
      fontSize: "2rem",
      fontWeight: 600,
      color: "#fff",
      textAlign: "center",
    },
    finishInfoSec: {
      fontSize: "2rem",
      fontWeight: 400,
      color: "#fff",
      textAlign: "center",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          background: `linear-gradient(347deg, hsl(159deg 94% 74%) 0%, hsl(157deg 91% 72%) 8%, hsl(154deg 89% 70%) 17%, hsl(152deg 86% 68%) 25%, hsl(149deg 84% 67%) 33%, hsl(146deg 81% 66%) 42%, hsl(142deg 79% 65%) 50%, hsl(155deg 83% 61%) 58%, hsl(164deg 86% 58%) 67%, hsl(171deg 87% 57%) 75%, hsl(176deg 86% 59%) 83%, hsl(180deg 85% 63%) 92%, hsl(185deg 95% 69%) 100%)`,
          color: "#005038",
          fontSize: "2.4rem",
          borderRadius: 8,
          fontWeight: 500,
        },
        text: {
          color: "#2DA46C",
          fontWeight: 500,
          fontSize: "1.85rem",
          position: "relative",

          "&::after": {
            content: "''",
            background: "#2DA46C",
            position: "absolute",
            bottom: 7,
            left: "50%",
            transform: "translateX(-50%)",
            width: "calc(100% - 18px)",
            height: 2,
          },
        },
        outlined: {
          fontSize: "1.2rem",
          fontWeight: 500,
          color: "#2DA46C",
          borderRadius: 8,
          borderColor: "#2DA46C",
          "&:hover, &:focus": {
            borderColor: "#2DA46C",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInput-root": {
            marginTop: 10,
            fontSize: "1.6rem",
          },
          "& .MuiInput-input": {
            padding: "4px 0 2px",
          },
          "& .MuiFormLabel-root": {
            fontSize: "1.6rem",
            fontWeight: 500,
            color: "#898989",
          },
          "& .MuiFormHelperText-root": {
            fontSize: "1rem",
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        track: {
          background: textGradient,
          borderRadius: 6,
          height: 12,
          border: "none",
        },
        thumb: {
          // border: "10px solid",
          // borderImageSlice: 1,
          // borderWidth: 2,
          // borderImageSource: textGradient,
          background: "#D9D9D9",
          // borderRadius: "50%",
        },
        rail: {
          opacity: 0.8,
          color: "#FFFFFF",
        },
        markLabel: {
          color: "#fff",
          fontSize: 20,
          fontWeight: 400,
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);

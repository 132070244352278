import { Dot } from "components/AnimBG/Dot";

export const randomInteger = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export function resizeWindow(canvas: HTMLCanvasElement) {
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;
}

function drawDot(this: CanvasRenderingContext2D, item: Dot) {
  if (item.x >= window.innerWidth) item.dx = -item.speed;
  if (item.x <= 0) item.dx = item.speed;
  if (item.y >= window.innerHeight) item.dy = -item.speed;
  if (item.y <= 0) item.dy = item.speed;
  item.x += item.dx;
  item.y += item.dy;
  this.fillStyle = "#fff";
  this.beginPath();
  this.arc(item.x, item.y, item.rad, 0, Math.PI * 2);
  this.fill();
}

export function drawDots(context: CanvasRenderingContext2D, dots: Array<Dot>) {
  dots.forEach(drawDot, context);
}

export const drawAnimation = function (this: {
  context: CanvasRenderingContext2D;
  dots: Array<Dot>;
  line: number;
}) {
  this.context.clearRect(0, 0, window.innerWidth, window.innerHeight);
  drawDots(this.context, this.dots);
  drawLine(this.context, this.dots, this.line);
};

export function drawLine(
  context: CanvasRenderingContext2D,
  dots: Array<Dot>,
  line: number,
) {
  dots.forEach(function (item, i, arr) {
    dots.forEach(function (item2) {
      if (
        item.x > item2.x - line &&
        item.x < item2.x + line &&
        item.y > item2.y - line &&
        item.y < item2.y + line
      ) {
        context.strokeStyle = "#fff";
        context.lineWidth = 0.2;
        context.beginPath();
        context.moveTo(item.x, item.y);
        context.lineTo(item2.x, item2.y);

        context.stroke();
      }
    });
  });
}
